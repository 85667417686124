import styled from 'styled-components'
import { breakpoints, device } from 'src/styles/breakpoints'

export const Section = styled.div`
  background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/hero-cafe-360/image.webp');
  background-position:  right;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #FDF8EE;
  min-height: 800px;
  background-color: #FDF8EE;

  @media ${device.tablet} {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/cafe-hero-768-1/image.webp');
    background-repeat: no-repeat;
    background-position:  right;
    min-height: 601px;
    background-color: #FDF8EE;
    background-size: contain;

    a {
      width: 303px;
    }
  }

  @media ${device.desktopLG} {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/cafe-hero-1024-1/image.webp');
    background-repeat: no-repeat;
    background-position:  right;
    background-color: #FDF8EE;
    background-size: contain;
    min-height: 799px;
    /* height: 736px; */

    a {
      width: 390px;
    }
  }

  @media ${device.desktopXL} {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/hero-cafe-1440-1/image.webp');
    background-repeat: no-repeat;
    background-position:  right;
    min-height: 799px;
    background-color: #FDF8EE;
    background-size: contain;

    a {
      width: 456px;
    }
  }

  img {
    width: 119px;
    height: 50px;
    margin-bottom: 24px;

    @media (min-width: ${breakpoints.xl}) {
      width: 259px;
      height: 108px;
      margin-bottom: 40px;
    }
  }
`
