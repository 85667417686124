import { breakpoints } from "src/styles/breakpoints"
import { laranja, orange } from "src/styles/colors"
import styled from "styled-components"

export const Section = styled.section`
   background-color: #FDF8EE;
   background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/cafe-360/image.webp');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top;
    min-height: 1358px;

    @media (min-width: ${breakpoints.md}) {
        background-color: #FDF8EE;
        background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/cafe-768/image.webp');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: top;
        min-height: 1267px;
    }

    @media (min-width: ${breakpoints.lg}) {
        background-color: #FDF8EE;
        background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/cafe-1024/image.webp');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: left;
        min-height: 958px;
    }

    @media (min-width: ${breakpoints.xl}) {
        background-color: #FDF8EE;
        background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/cafe-1440/image.webp');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: left;
        min-height: 1076px;
    }

    p {
        color: #1E1E1E;
    }

    h2 {
        color: ${laranja.terra};
        margin-top: 40px;
    }

    svg {
        width: 32px;
        height: 32px;
    }

    .subtitle {
        margin-bottom: 43px;
    }

`
export const Button = styled.button`
    background-color: ${orange.extra};
    width: 100%;
    height: 48px;
    border-radius: 8px;
    color: #fff;
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    margin-top: 17px;

    &:focus {
        outline: none;
    }

    @media (min-width: ${breakpoints.md}) {
        margin-top: 40px;
    }

`
