import { breakpoints } from "src/styles/breakpoints"
import styled from "styled-components"

export const Section = styled.section`
   background: url('https://central-imagens.bancointer.com.br/images-without-small-versions/dobra4-360-1/image.webp') no-repeat;
   background-position-y: top;
   background-color: #943D15;
   min-height: 800px;
   background-size: contain;

   @media (min-width: ${breakpoints.md}) {
      background: url('https://central-imagens.bancointer.com.br/images-without-small-versions/dobra4-768-1/image.webp') no-repeat;
      background-position: left;
      background-color: #943D15;
      min-height: 500px;
   }

   @media (min-width: ${breakpoints.lg}) {
      background: url('https://central-imagens.bancointer.com.br/images-without-small-versions/dobra4-1024/image.webp') no-repeat ;
      background-position: left;
      background-color: #943D15;
      min-height: 650px;
   }

   @media (min-width: ${breakpoints.xl}) {
      background: url('https://central-imagens.bancointer.com.br/images-without-small-versions/cafe-dobra4-1440/image.webp') no-repeat ;
      background-position: left;
      background-color: #943D15;
      min-height: 759px;
   }

  

`
