import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { grayscale, orange, laranja } from 'src/styles/colors'

export const Section = styled.section`
  background-color: #FDF8EE;

  h1 {
    color: ${laranja.terra};
  }
  
  .react-multiple-carousel__arrow {
    svg {
      fill: ${orange.extra} !important;
    }
  }
  .react-multi-carousel-dot {
    button {
      background-color: ${orange.extra} !important;
    }
  }
    /* .react-multi-carousel-dot-list {
      bottom: 3px;
    } */
  
`

export const CarouselItem = styled.div`
  height: 300px;
  min-width: 280px;
  background-repeat: no-repeat;
  background-size: contain;

  .text {
    @media ${device.desktopXL} {
      line-height: 32px!important;
    }
  }

  @media ${device.tablet} {
    height: 456px;
    min-width: 280px;
  }

  @media ${device.desktopLG} {
    height: 615px;
  }

  @media ${device.desktopXL} {
    height: 600px;
    min-width: 360px;
  }
`
