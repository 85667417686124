import React from 'react'
import Layout from 'src/layouts/BaseLayout'
import pageContext from './pageContext.json'

import { Wrapper } from './style'

// Sections
import Cafes from './sections/hero/_index'
import FacaUmTour from './sections/faca-um-tour/_index'
import ProntoParaTeReceber from './sections/pronto-para-te-receber/_index'
import InterCafeBelvedere from './sections/inter-cafe-belvedere/_index'
import OutrasUnidades from './sections/outras-unidades/_index'
import OJeitoInteligente from './sections/o-jeito-inteligente/_index'

const Hero = () => {
  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        <Cafes />
        <FacaUmTour />
        <ProntoParaTeReceber />
        <InterCafeBelvedere />
        <OutrasUnidades />
        <OJeitoInteligente />
      </Layout>
    </Wrapper>
  )
}

export default Hero
