import React from 'react'
// import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { Section } from './style'
import IcHouseCheck from '@interco/icons/core/finance/ic_house_check'
import IcCoffee from '@interco/icons/core/home-objects/ic_coffee'

const OJeitoInteligente = () => {
  // const [ sendDatalayerEvent ] = useDataLayer()
    return (
      <Section className='py-5 d-flex align-items-end align-items-lg-center'>
        <div className='container'>
          <div className='row d-flex justify-content-md-end'>
            <div className='col-12 col-lg-5'>
              <h2 className='fs-28 lh-33 fs-md-56 lh-md-61 fw-700 mb-5'>O jeito inteligente de fazer o seu evento.</h2>
              <p className='fs-16 lh-19 fs-md-18 lh-md-21 fw-400  subtitle'>
                Se você está pensando em fazer um evento corporativo, o Inter Café é o lugar certo. <br /> Um novo espaço no Belvedere, com capacidade pra até 50 pessoas e tudo pra deixar os encontros da sua empresa ainda melhores.
              </p>
              <div className='d-flex align-items-center mb-3'>
                <IcHouseCheck height={32} width={32} color='#000000' />
                <p className='mb-0 ml-3'>Infraestrutura completa</p>
              </div>
              <div className='d-flex align-items-center'>
                <div>
                  <IcCoffee height={32} width={32} color='#000000' />
                </div>
                <p className='mb-0 ml-3'>Welcome Coffee | Coffee Break | Almoço | Happy Hour | Jantar</p>
              </div>
              <h2 className='fs-24 lh-28 fw-700'>Não organize seu evento sem falar com a gente!</h2>
              <p className='fs-18 lh-21 fw-400 text-grayscale--500 mt-3'>Descontos especiais para clientes Inter PJ</p>
              {/* <Button
                onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_06',
                  element_action: 'click button',
                  element_name: 'Peça seu orçamento',
                  section_name: 'O jeito inteligente de fazer o seu evento.',
                })
                }}
              >Peça seu orçamento
              </Button> */}
            </div>
          </div>
        </div>
      </Section>
    )
}

export default OJeitoInteligente
