import React from 'react'

import OutrasUnidadeJSON from '../../assets/data/OutrasUnidades.json'

import { Section, Table, TableContainer } from './style'
import { H2Citrina } from 'src/components/Text/Headings'

type ProntoParaTeReceberProps = {
  title: string;
  address: string;
  dateHtml: string;
  dateLine1?: string;
  dateLine2?: string;
  addressLine1?: string;
  addressLine2?: string;
}

const OutrasUnidades = () => {
  return (
    <>
      <section id='enderecos' className='py-5 d-flex align-items-end align-items-md-center d-none d-md-block'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <h2 className='fs-md-42 lh-52 text-grayscale--500 text-center fw-600 d-none d-md-block mb-md-0'>
                Nossas unidades
              </h2>
            </div>
          </div>
        </div>
      </section>
      <Section className='d-md-none py-5'>
        {OutrasUnidadeJSON.map((item: ProntoParaTeReceberProps, index: number) => (
          <div
            key={`sm_${item.title}_${index}`}
            className='content-address py-4'
          >
            <div className='container'>
              <div className='row'>
                <div className='col-12'>
                  <p className='fs-20 lh-25 text-grayscale--500 fw-600 mb-3' dangerouslySetInnerHTML={{ __html: item.title }} />
                  <p className='fs-14 fs-lg-18 lh-32 lh-md-17 text-grayscale--500 mb-0 text' dangerouslySetInnerHTML={{ __html: item.address }} />
                  <p className='fs-14 fs-lg-18 lh-20 text-grayscale--500 text mb-0' dangerouslySetInnerHTML={{ __html: item.dateHtml }} />
                </div>
              </div>
            </div>
          </div>
        ))}
      </Section>
      <Table className='d-none d-md-block'>
        <div className='container'>
          <TableContainer className='d-flex mt-5 px-0 py-md-4 top'>
            <div className='col-lg-4 py-5 py-md-3 primary align-items-center justify-content-start content pl-3'>
              <strong className='fs-md-24 text-grayscale--500 subtitle'>Unidade</strong>
            </div>
            <div className='col-lg-4 py-5 py-md-3 align-items-center justify-content-start content pl-3'>
              <strong className='fs-md-24 text-grayscale--500 subtitle'>Endereço</strong>
            </div>
            <div className='col-lg-4 py-5 py-md-3 align-items-center justify-content-start content pl-3 pl-xl-0'>
              <strong className='fs-md-24 lh-md-26 text-grayscale--500 subtitle'><span className='d-block d-xl-inline text-left'>Horário de</span> funcionamento</strong>
            </div>
          </TableContainer>
          {OutrasUnidadeJSON.map((item: ProntoParaTeReceberProps, index: number) => (
            <TableContainer className='d-flex px-0' key={`md_${item.title}_${index}`}>
              <div className={`col-lg-4 py-md-5 d-flex align-items-center justify-content-start content pl-3 ${index % 2 !== 0 ? 'table-zebra' : ''}`}>
                <span className='fs-md-18 text-grayscale--500 d-block'>{item.title}</span>
              </div>
              <div className={`col-lg-4 py-md-5 d-flex align-items-center justify-content-start content pl-3 ${index % 2 !== 0 ? 'table-zebra' : ''}`}>
                <span className='fs-md-16 text-grayscale--500 d-block lh-md-22 text-left'>
                  <span className='d-md-block'>{item.addressLine1}</span>{item.addressLine2}

                </span>

              </div>
              <div className={`col-lg-4 py-md-5 d-flex flex-column align-items-start justify-content-center content pl-3 pl-xl-0 ${index % 2 !== 0 ? 'table-zebra' : ''}`}>
                {item.dateHtml && (<span className='fs-md-16 text-grayscale--500 d-block'>{item.dateHtml}</span>)}
                <span className='fs-md-16 text-grayscale--500 d-block'>{item.dateLine1}</span>
                {item.dateLine2 && (<span className='fs-md-16 text-grayscale--500 d-block'>{item.dateLine2}</span>)}
              </div>
            </TableContainer>
            ))}
        </div>
      </Table>
    </>
  )
}

export default OutrasUnidades
