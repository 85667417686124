import React from 'react'

import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'

import ProntoParaTeReceberJSON from '../../assets/data/ProntoParaTeReceber.json'
import CardCafe from './_Card'

import { Section } from './style'

type ProntoParaTeReceberProps = {
  image: string;

}

const ProntoParaTeReceber = () => {
  return (
    <Section className='py-5 d-flex align-items-md-center'>
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-between'>
          <div className='col-12 col-md-6 col-xl-5'>
            <h2 className='fs-24 fs-md-32 fs-lg-40 fs-xl-48 lh-30 lh-md-40 lh-lg-50 lh-xl-60 text-grayscale--500 fw-700'>
              O que é o Inter Café?
            </h2>
            <p className='fs-14 fs-lg-18 lh-16 lh-lg-22 text-grayscale--500 fw-400 mt-3'>
              Pra relaxar ou trabalhar, pro café da manhã ou lanche da tarde, o Inter Café é pra toda hora.
            </p>
            <p className='fs-14 fs-lg-18 lh-16 lh-lg-22 text-grayscale--500 fw-400  mt-3'>
              São mais de 20 opções de cafés gourmets e pratos incríveis pra você que não abre mão de aproveitar cada momento do dia.
            </p>
          </div>
          <div className='col-12 col-md-6'>
            <DefaultCarousel
              sm={{ items: 1 }}
              md={{ items: 1, partialVisibilityGutter: 30 }}
              lg={{ items: 1, partialVisibilityGutter: 30 }}
              xl={{ items: 1, partialVisibilityGutter: 50 }}
            >
              {
                ProntoParaTeReceberJSON.map((item: ProntoParaTeReceberProps) => (
                  <CardCafe src={item.image} key={item.image} />
                ))
              }
            </DefaultCarousel>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default ProntoParaTeReceber
