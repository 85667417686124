import React from 'react'

import { Section } from './style'
import ScrollTo from 'src/components/ScrollTo'

const InterCafeBelvedere = () => {
    return (
      <Section className='py-5 d-flex align-items-md-center align-items-end'>
        <div className='container'>
          <div className='row d-flex justify-content-md-end'>
            <div className='col-12 col-md-6 col-lg-5'>
              <h2 className='fs-28 lh-33 fs-md-40 lh-md-44 fs-lg-56 lh-lg-61 fs-xl-56 lh-xl-61 fw-700 text-white'>
                Inter Café Belvedere
              </h2>
              <p className='fs-16 lh-19 fs-md-16 lh-md-19 fs-lg-18 lh-lg-21 fs-xl-18 lh-xl-21 text-white fw-400 mb-3'>
                Sempre de portas abertas ao público, leva a essência de ser Inter pra todo mundo. Cardápio com sabores únicos e grande variedade de cafés de diversos países, em um ambiente inovador e diferenciado.
              </p>
              <ScrollTo
                to='#enderecos'
                section='dobra_04'
                sectionName='Inter Café Belvedere'
                elementName='Venha nos visitar'
                styles='btn btn--lg mb-md-4 btn--lg text-orange--extra bg-white text-none rounded-2 fw-700 mt-3'
              >
                Venha nos visitar
              </ScrollTo>
            </div>
          </div>
        </div>
      </Section>
    )
}

export default InterCafeBelvedere
