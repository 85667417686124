import React from 'react'

// Components
import ScrollTo from 'src/components/ScrollTo'
import { Section } from './style'
import ImageWebp from 'src/components/ImageWebp'

const Header = () => {
  return (
    <Section className='py-5 d-flex align-items-end align-items-md-center'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-6 col-lg-5 col-xl-6'>
            <ImageWebp pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/hero-logo-inter-cafe/image.webp' altDescription='' arrayNumbers={[ 259, 259, 259, 259 ]} arrayNumbersHeight={[ 108, 108, 108, 108 ]} />
            <h1 className='fs-24 lh-28 fs-md-28 fs-lg-33 fs-xl-48 lh-30 lh-md-40 lh-lg-50 fs-xl-56 lh-xl-61 text-grayscale--500 fw-500 mb-3'>
              A escolha inteligente e saborosa pro seu dia
            </h1>
            <p className='fs-14 lh-16 fs-md-16 lh-md-19 fs-lg-18 lh-lg-21 text-grayscale--500 pr-xl-5 mt-3 mb-4'>
              Viva uma experiência Inter com muita originalidade e uma seleção especial de cafés.
            </p>
            <ScrollTo
              to='#tour-virtual'
              section='dobra_01'
              sectionName='A escolha inteligente e saborosa pro seu dia'
              elementName='Saiba mais'
              styles='d-md-flex btn btn--lg bg-orange--extra text-white text-none mw-100 mt-4'
            >
              Saiba mais
            </ScrollTo>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Header
