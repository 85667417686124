import React from 'react'
import { breakpoints, device } from 'src/styles/breakpoints'
import styled from 'styled-components'

const CardWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin-right: 16px;

  @media ${device.tablet} {
    max-height: 456px;
  }

  @media ${device.desktopLG} {
    max-height: 615px;
  }

  @media ${device.desktopXL} {
    max-width: 90%;
  }
`

const CardImg = styled.img`
  width: 100%;
  height: 364px;
  border-radius: 72px;

  @media (min-width: ${breakpoints.md}){
    width: 288px;
    height: 288px;
  }

  @media (min-width: ${breakpoints.lg}){
    width: 408px;
    height: 408px;
  }

  @media (min-width: ${breakpoints.xl}){
    width: auto;
    height: 486px;
  }
`
const CardCafe = ({ src }: {src: string}) => {
  return (
    <CardWrapper>
      <CardImg
        src={src}
      />
    </CardWrapper>
  )
}

export default CardCafe
